/*** IMPORTS FROM imports-loader ***/
(function() {

(function (window) {
 
  window.__env = window.__env || {};

  // API url
  if (window.location.hostname === 'localhost') {
    window.__env.apiUrl = '/gtw';
    window.__env.customApiUrl = '/custom';
    window.__env.adminApiUrl = '/admin';
    window.__env.commonApiUrl = '/batch';
    window.__env.schedulerApiUrl = '/scheduler';
    window.__env.pdfEngineApiUrl = '/pdf-engine';
    window.__env.bulkPdfEngineApiUrl = '/bulk-pdf-engine';
    window.__env.gtwEfileApiUrl = '/gtw-efile-api'
    
    window.__env.goldSyncApiUrl = window.__env.schedulerApiUrl;
    window.__env.notificationApiUrl = window.__env.schedulerApiUrl;
    
  }
  else {
    if(window.location.hostname.indexOf("dev") === -1){
      var enableAllLogs = localStorage.getItem('enableAllLogs');
      if (!enableAllLogs) {
        console.log = function() {
          
        };
      }
    }

    window.__env.apiUrl = '{GTW_GATEWAY_URL}/gtw';
    window.__env.customApiUrl = '{GTW_GATEWAY_URL}/custom';
    window.__env.adminApiUrl = '{GTW_GATEWAY_URL}/admin';
    window.__env.commonApiUrl = '{GTW_GATEWAY_URL}/batch';
    window.__env.schedulerApiUrl = '{GTW_GATEWAY_URL}/scheduler';
    window.__env.pdfEngineApiUrl = '{GTW_GATEWAY_URL}/pdf-engine';
    window.__env.bulkPdfEngineApiUrl = '{GTW_GATEWAY_URL}/bulk-pdf-engine';
    window.__env.gtwEfileApiUrl = '{GTW_GATEWAY_URL}/gtw-efile-api';
    
    window.__env.goldSyncApiUrl = window.__env.schedulerApiUrl;
    window.__env.notificationApiUrl = window.__env.schedulerApiUrl;
    window.__env.enableSourceMap = '{GTW_ENABLE_SOURCEMAPS}'
    window.__env.gateWay = '{GTW_GATEWAY_URL}';

    
  }
  console.log('document.location.hostname', document.location.hostname);

}(this));

}.call(window));